module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_esli_d7ae337nzczujxcev7ezf6fb6y/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4.0_ptjhmv7jdmr2amhil7hn75ptqe/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JP Notes","short_name":"JP Notes","start_url":"/","background_color":"#000000","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ade3a6609fb53adc1f4d14681bbc6015"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferut_5cjojkgwiqpafexl5soxticxna/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://notes.mrjp.xyz"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@_iqcj2bvqgpjwgg57jku2kiee6u/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-T309JV43FZ"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4.0.8_react-dom@18.3.1_react@18.2_aexeb65ifmeyq4eay6b6xybkpm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
